<template>
	<page>
    <module>
		<module-row>
			<module-col enable-flex >
				<m-operate>
					<c-button v-if="auth('b_dic_edit')"  color="main"  @click="editDicType()">新增</c-button>
				</m-operate>
				<c-table ref="dicTypeTable" height="grow" @rowClick="selectDicType"> <!--字典分类名称-->
					<c-table-column
						label="字典分类"
						name="type_name"
						width="200"
					>
						<template v-slot="row">
							<span>{{row.data.type_name}}</span>
							<span v-if="row.data.is_sys == 1">【{{row.data.type_code}}】</span>
						</template>
					</c-table-column>
					<c-table-column
						label="分类描述"
						name="remark"
						width="150"
					></c-table-column>
					<c-table-column
						label="创建时间"
						name="create_date"
						width="70"
					></c-table-column>
					<c-table-column
						type="button"
						fixed="right"
						label="操作"
						width="100"
					>
						<template v-slot="row">
							<c-table-button v-if="auth('b_dic_edit') && row.data.is_sys == 2" @click="editDicType(row.data)">编辑</c-table-button>
							<c-table-button v-if="auth('b_dic_edit') && row.data.is_sys == 2" @click="removeDicType(row.data)">删除</c-table-button>
						</template>
					</c-table-column>
				</c-table>
			</module-col>
			<module-col enable-flex size="40%">
				<m-search ref="search" @search="searchDiceTable">
					<m-search-item label="字典代码">
						<c-input name="code"></c-input>
					</m-search-item>
					<m-search-item label="字典值">
						<c-input name="keyword"></c-input>
					</m-search-item>
				</m-search>
				<m-operate>
					<c-button v-if="!editFlag && dic_type_id!=-1 && auth('b_dic_edit')"  color="main"  @click="edit()">编辑</c-button>
						<c-button v-if="editFlag && dic_type_id!=-1 && auth('b_dic_edit')"  color="main"  @click="save()">保存</c-button>
						<c-button v-if="editFlag && dic_type_id!=-1 && auth('b_dic_edit')"  color="red"  @click="add()">添加一行</c-button>
					<c-button v-if="!editFlag && dic_type_id!=-1 && auth('b_dic_edit')"  color="red"  @click="remove()">删除</c-button>
				</m-operate>
				<c-table ref="dicInfoTable" height="grow" :paging="false">
					<c-table-column
						type="selector"
						v-show="editFlag"
						width="10"
					></c-table-column>
					<c-table-column
						label="字典代码"
						name="code"
						width="120"
					>
					  <template #default="{data}">
						<div v-if="!editFlag">{{ data.code }}</div>
						<c-input v-else name="code" required v-model="data.code" @change="changeColumn(data)"></c-input>
					  </template>
					</c-table-column>
					<c-table-column
						label="字典值"
						name="value"
						width="120"
						required
					>
					  <template #default="{data}">
						<div v-if="!editFlag">{{ data.value }}</div>
						<c-input v-else name="value" required v-model="data.value" @change="changeColumn(data)"></c-input>
					  </template>
					</c-table-column>
					<!-- key-->
					<c-table-column
						label="最后更新时间"
						name="edit_date"
						width="80"
					>
					</c-table-column>
				</c-table>
			</module-col>
		</module-row>
    </module>
    <c-dialog ref="dialog" :title="editState ? '编辑字典分类' : '新增字典分类'" width="500" @resolve="editHandle">
		<c-form ref="form" vertical unit-width="100%">
			<c-input type="hidden" name="id"></c-input>
			<c-form-item label="分类名称" required>
				<c-input name="type_name"></c-input>
			</c-form-item>
			<c-form-item label="分类描述">
				<c-input name="remark" type="textarea"></c-input>
			</c-form-item>
		</c-form>
    </c-dialog>
	</page>
</template>

<script>
  export default {
        name: 'm_dic',

	    components: {
	    },

		data() {
			return {
		        dicInfoList:[],
		        editFlag:false,             //true=编辑
		        dic_type_id:-1,             //字典分类ID
		        editState:false,
		        dic_info_id:-999
			}
		},
		mounted() {
	      this.searchDicTypeHandle();
		},
		methods: {
			//重置搜索条件
			reset(){
			
			},
			//选中某分类
			selectDicType(row) {
				this.is_sys = row.data.is_sys;
				this.dic_type_id = row.data.id;
				this.$refs.search.search();
			},
			//新建/编辑字典分类
			editDicType(data) {
				if (data){
				  this.editState = true;
				}else {
				  this.editState = false;
				}
				//填充form表单
				this.$refs.form.clear();
				this.$refs.form.set(data || {});
				this.$refs.dialog.open();
			},
			//删除字典分类
			removeDicType(data) {
				this.$confirm({
				  message: '确定要删除分类【' + data.type_name + '】吗？',  // 提示内容,
				  // buttonName: '确定',  // 确定按钮文字
				  cancelButton: true,  // 是否显示取消按钮
				  resolve: () => {
					this.request({
					  url: '/basic/dic/del_type',
					  data: {dic_type_id:data.id},
					  loading: true,
					  success: data => {
						this.$message({
						  message: '删除字典分类成功',  // 提示内容,
						  type: 'success'
						});
						this.$refs.dicTypeTable.update();
					  }
					});
				  },  // 确定按钮回调
				  cancel: () => {},  // 取消按钮回调
				});
			},
			//编辑字典分类-提交
			editHandle(stop, next) {
				stop();
				this.$refs.form.submit({
					url: this.editState?'/basic/dic/edit_type':'/basic/dic/add_type',
					rule: {
						type_name: '请输入字典分类名称'
					},
					dataFormatter: data => {
						data.dic_type_id = data.id;
						data.name = data.type_name;
						return data;
					},
					success: (data, res, msg) => {
						this.$message({
						  type: 'success',
						  message: '操作成功'
						});
						next();
						this.$refs.dicTypeTable.update();
					}
				});
			},
			//查询字典分类
			searchDicTypeHandle(data) {
				this.$refs.dicTypeTable.load({
					url: '/basic/dic/type_list',
					data
				});
			},
			//查询字典数据
			searchDiceTable(data) {
				data.dic_type_id = this.dic_type_id;
				this.$refs.dicInfoTable.load({
					url: '/basic/dic/info_list',
					data
				});
			},
			//编辑字典数据
			edit() {
				this.editFlag = true;
			},
			//变更字典数据
			changeColumn(data) {
				//判断是否已存在
				var index = this.dicInfoList.findIndex(item => item.id === data.id);
				if(index == -1){
					this.dicInfoList.push(data);
				}else{
					//已存在则直接替换对象
					this.dicInfoList[index] = data;
				}
			},
			//添加字典行
			add(){
				var data = {};
				data.id = this.dic_info_id;
				data.code = '';
				data.value = '';
				this.dic_info_id = this.dic_info_id - 1;
				this.$refs.dicInfoTable.push(data);
			},
			//删除字典行
			remove(item){
				var list = item ? [{
					data: item
				}] : this.$refs.dicInfoTable.getRow('selected');
				if (!list.length) {
					this.$message({
						type: 'fail',
						message: '请选择要删除的字典数据'
					});
					return;
				}
				var ids =  Array.from(list, item => item.data.id||0);
				this.$confirm({
					message: '确定要删除'+ids.length+'个字典吗？',  // 提示内容,
					// buttonName: '确定',  // 确定按钮文字
					cancelButton: true,  // 是否显示取消按钮
					resolve: () => {
						this.request({
						  url: '/basic/dic/del_info',
						  data: {dic_info_ids:ids},
						  loading: true,
						  success: data => {
								this.$message({
								  message: '删除成功',  // 提示内容,
								  type: 'success'
								});
								this.dicInfoList = [];
								this.dic_info_id = -99;
								this.editFlag = false;
								this.$refs.dicInfoTable.update();
						  }
						});
					},  // 确定按钮回调
					cancel: () => {},  // 取消按钮回调
				});
			},
			//保存数据
			save() {
				if (this.dicInfoList && this.dicInfoList.length > 0){
					
					var datar = this.$refs.dicInfoTable.getRow();
					var all_list = Array.from(datar, item => item.data);
					
					//循环判断数据，不允许为空，不允许重复
					for (let i = 0; i < this.dicInfoList.length; i++) {
						var item1 = this.dicInfoList[i];
						//系统字典
						if(this.is_sys == 1){
							if (!item1.code || !item1.value){
								this.$message({
									type: 'fail',
									message: '请完整填写必填信息'
								});
								return;
							}
						}else{
							if (!item1.value){
								this.$message({
									type: 'fail',
									message: '请完整填写必填信息'
								});
								return;
							}
						}
						
						for (let j = 0; j < all_list.length; j++) {
							var item2 = all_list[j];
							//系统字典
							if(this.is_sys == 1){
								if (item1.id != item2.id && (item1.code == item2.code || item1.value==item2.value)){
									this.$message({
										type: 'fail',
										message: '存在重复数据，请检查'
									});
									return;
								}
							}else{
								if (item1.id != item2.id && item1.value==item2.value){
									this.$message({
										type: 'fail',
										message: '存在重复数据，请检查'
									});
									return;
								}
							}
						}
					}
					this.request({
						url: '/basic/dic/batch_edit_info',
						data: {
							dic_type_id: this.dic_type_id,
							dic_info_list: this.dicInfoList
						},
						success: data => {
							this.$message({
							  type: 'success',
							  message: '保存成功'
							});
						}
					});
				}else {
					this.$message({
						type: 'success',
						message: '操作成功'
					});
				}
				this.dicInfoList = [];
				this.dic_info_id = -99;
				this.editFlag = false;
			}
		}
	}
</script>